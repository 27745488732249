import { useContext } from '@nuxtjs/composition-api';
import { QUEST_3_BUNDLE_SKU, QUEST_GOOSE_BUNDLE_SKU } from '../constants.client';
export const useVideoUrls = () => {
    const { $config } = useContext();
    const { videoBaseUrl } = $config;
    const localizedUrls = {
        [QUEST_3_BUNDLE_SKU]: {
            en: {
                // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/5d2fdbc148f24cdd0a4d7d3c48a2109c/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%5d2fdbc148f24cdd0a4d7d3c48a2109c%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D3s',
                mp4: `${videoBaseUrl}20230627_BEAST_VR_Lens_Video_16x9.mp4`,
                videoPoster: `${videoBaseUrl}quest3_en_thumbnail.png`,
            },
            ja: {
                // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/5fd482b83dc482424292af82516615e0/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F5fd482b83dc482424292af82516615e0%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s',
                mp4: `${videoBaseUrl}20230627_BEAST_VR_Lens_Video_Japanese_16x9.mp4`,
                videoPoster: `${videoBaseUrl}quest3_ja_thumbnail.png`,
            },
            fr: {
                // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/773a83b0691ed69e6eaa5ed8348db382/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F773a83b0691ed69e6eaa5ed8348db382%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s',
                mp4: `${videoBaseUrl}20230627_BEAST_VR_Lens_Video_French_16x9.mp4`,
                videoPoster: `${videoBaseUrl}quest3_fr_thumbnail.png`,
            },
            de: {
                // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/f1dbec032455683a94a316a32cc453fe/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2Ff1dbec032455683a94a316a32cc453fe%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D5s',
                mp4: `${videoBaseUrl}20230627_BEAST_VR_Lens_Video_German_16x9.mp4`,
                videoPoster: `${videoBaseUrl}quest3_de_thumbnail.png`,
            },
        },
        [QUEST_GOOSE_BUNDLE_SKU]: {
            en: {
                // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/4fcdc40c762f9e6729eb2ff18c568ddb/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F4fcdc40c762f9e6729eb2ff18c568ddb%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
                mp4: `${videoBaseUrl}20240814_ZenniGooseOwlInserts_16X9_V9GK.mp4`,
                videoPoster: `${videoBaseUrl}quest3s_en_thumbnail.png`,
            },
            ja: {
                // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/726a77b18589595257df3319b7cbef46/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F726a77b18589595257df3319b7cbef46%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
                mp4: `${videoBaseUrl}20240814_ZenniGooseOwlInserts_JAPANESE_16X9_V9GK.mp4`,
                videoPoster: `${videoBaseUrl}quest3s_ja_thumbnail.png`,
            },
            fr: {
                // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/4fbd3f2426dda4a50d17ac55d53c27d8/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F4fbd3f2426dda4a50d17ac55d53c27d8%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
                mp4: `${videoBaseUrl}20240814_ZenniGooseOwlInserts_FRENCH_16X9_V9GK.mp4`,
                videoPoster: `${videoBaseUrl}quest3s_fr_thumbnail.png`,
            },
            de: {
                // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/b89b3a814f49f80aa6586a49f4eaacff/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2Fb89b3a814f49f80aa6586a49f4eaacff%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600',
                mp4: `${videoBaseUrl}20240814_ZenniGooseOwlInserts_GERMAN_16X9_V1GK.mp4`,
                videoPoster: `${videoBaseUrl}quest3s_de_thumbnail.png`,
            },
        },
    };
    const raskullVideoUrl = {
        // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/e8a2edc1ec6d2c67a7008c361e334623/iframe?preload=true&poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2Fe8a2edc1ec6d2c67a7008c361e334623%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26s',
        mp4: `${videoBaseUrl}raskull.mp4`,
        videoPoster: `${videoBaseUrl}raskull_thumbnail.png`,
    };
    const antiFogVideoUrl = {
        // embed: 'https://customer-ej736ldfw6tz4aan.cloudflarestream.com/165bfb9bf43577cbb086f3bdf5eeaa85/iframe?poster=https%3A%2F%2Fcustomer-ej736ldfw6tz4aan.cloudflarestream.com%2F165bfb9bf43577cbb086f3bdf5eeaa85%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26',
        mp4: `${videoBaseUrl}20230627_BEAST_VR Antifog Lens Video_16x9.mp4`,
        videoPoster: `${videoBaseUrl}antifog_thumbnail.png`,
    };
    const INSTALL_VIREO_URL_MAP = {
        [QUEST_3_BUNDLE_SKU]: raskullVideoUrl,
    };
    return {
        localizedUrls,
        raskullVideoUrl,
        antiFogVideoUrl,
        INSTALL_VIREO_URL_MAP,
    };
};
